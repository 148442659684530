<template>
    <div class="columns">
        <div class="sidebar-left sidebar-thirds">
            <table>
                <thead>
                    <tr>
                        <th>{{text.report_total_advert_published}}</th>
                    </tr>
                </thead>
                <tbody v-if="showTotalAdvertsPublishedGraph">
                    <tr>
                        <td><Form v-model="totalAdvertsPublishedBusinessUnit" ref="totalAdvertsPublishedForm" /></td>
                    </tr>
                    <tr>
                        <td><ChartVerticalBarReportTotalAdvertsPublished :chartData="totalAdvertsPublishedGraphData" /></td>
                    </tr>
                    <tr>
                        <td><a href="#" :title="text.download_csv" @click="onReportDownloadClick($event, 'totalAdvertsPublished')" @keyup.enter="onReportDownloadClick($event, 'totalAdvertsPublished')">{{text.download_csv}}</a></td>
                    </tr>
                </tbody>
                <tbody v-else>
                    <tr>
                        <td>{{text.no_results}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="sidebar-left sidebar-thirds">
            <table>
                <thead>
                    <tr>
                        <th colspan="2">{{text.report_user_caseload_limited}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td colspan="2"><Form v-model="userCaseLoadBusinessUnit" ref="totalAdvertsPublishedForm" /></td>
                    </tr>
                    <tr v-for="row in userCaseLoadTableData" :key="row.id">
                        <td>{{row.name}}</td>
                        <td>{{row.count}}</td>
                    </tr>
                    <tr v-if="userCaseLoadTableData.length > 0">
                        <td colspan="2"><a href="#" :title="text.download_csv" @click="onReportDownloadClick($event, 'userCaseLoad')" @keyup.enter="onReportDownloadClick($event, 'userCaseLoad')">{{text.download_csv}}</a></td>
                    </tr>
                    <tr v-else>
                        <td colspan="2">{{text.no_results}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="sidebar-right sidebar-thirds">
            <table>
                <thead>
                <tr>
                    <th colspan="2">{{text.report_business_unit_caseload_limited}}</th>
                </tr>
                </thead>
                <tbody v-if="businessUnitCaseloadTableData.length > 0">
                <tr v-for="row in businessUnitCaseloadTableData" :key="row.id">
                    <td>{{row.name}}</td>
                    <td>{{row.count}}</td>
                </tr>
                <tr>
                    <td colspan="2"><a href="#" :title="text.download_csv" @click="onReportDownloadClick($event, 'businessUnitCaseLoad')" @keyup.enter="onReportDownloadClick($event, 'businessUnitCaseLoad')">{{text.download_csv}}</a></td>
                </tr>
                </tbody>
                <tbody v-else>
                <tr>
                    <td colspan="2">{{text.no_results}}</td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    import {log} from "@/shared/utils";
    import lang from "@/shared/lang";
    import {showDefaultServerErrorMessage} from "@/shared/utils";

    import {reportActionsMixin} from "@/views/mixins/reportActions";

    import ChartVerticalBarReportTotalAdvertsPublished from "@/components/chart-verticalbar-reporttotaladvertspublished";
    import Form from "@/components/form/form";

    export default {
        name: "Reporting",
        components: {Form, ChartVerticalBarReportTotalAdvertsPublished},
        mixins: [reportActionsMixin],
        data() {
            return {
                showTotalAdvertsPublishedGraph: false,
                totalAdvertsPublishedGraphData : [],
                userCaseLoadTableData : [],
                businessUnitCaseloadTableData : [],
                totalAdvertsPublishedBusinessUnit : [
                    {
                        id: 1,
                        type: 'select',
                        name: 'businessUnit',
                        label: 'Filter by department',
                        placeholder: "",
                        tooltip: "",
                        value: "all",
                        options: [],
                        classString: "hide-label",
                    },
                ],
                userCaseLoadBusinessUnit : [
                    {
                        id: 1,
                        type: 'select',
                        name: 'businessUnit',
                        label: 'Filter by department',
                        placeholder: "",
                        tooltip: "",
                        value: "all",
                        options: [],
                        classString: "hide-label",
                    },
                ]
            }
        },
        computed: {
            text() {
                return lang;
            },
            defaultBusinessUnitSelectOption() {
                return {value : "all", text : this.text.showing_all_business_units};
            },
            selectedUserCaseloadBusinessUnitValue() {
                return this.userCaseLoadBusinessUnit.find(row => row.name === 'businessUnit').value;
            },
            selectedTotalAdvertsPublishedBusinessUnitValue() {
                return this.totalAdvertsPublishedBusinessUnit.find(row => row.name === 'businessUnit').value;
            },
        },
        methods: {
            async getUserCaseloadData(businessUnitFilter = false){
                let params = { 'Page' : 1, 'ItemsPerPage' : 3};

                if(businessUnitFilter){
                    params.businessUnitFilter = businessUnitFilter;
                }

                let result = await this.loadReportUserCaseLoad(params, false);

                this.userCaseLoadTableData = result.data.data

                log(["Get user caseload data", this.userCaseLoadTableData]);
            },
            async getBusinessUnitCaseloadData(businessUnitFilter = false){
                let params = { 'Page' : 1, 'ItemsPerPage' : 3};

                if(businessUnitFilter){
                    params.businessUnitFilter = businessUnitFilter;
                }

                let result = await this.loadReportBusinessUnitCaseLoad(params, false);

                this.businessUnitCaseloadTableData = result.data.data

                log(["Get business unit caseload data", this.userCaseLoadTableData]);
            },
            async initBusinessUnits() {
                let result = await this.loadBusinessUnits();

                log(["Business Unit Result", result]);

                if(result.status === 200){
                    let newOptions = [this.defaultBusinessUnitSelectOption];
                    result.data.data.forEach(businessUnit => {
                        newOptions.push({value : businessUnit.id, text : businessUnit.name});
                    });

                    this.totalAdvertsPublishedBusinessUnit.find(row => row.name === 'businessUnit').options = newOptions;
                    this.userCaseLoadBusinessUnit.find(row => row.name === 'businessUnit').options = newOptions;
                }
                else
                {
                    showDefaultServerErrorMessage("Load Business Unit");
                }
            },

        },
        async mounted() {
            await this.getTotalAdvertsPublishedGraphData();
            await this.getUserCaseloadData();
            await this.getBusinessUnitCaseloadData();
            await this.initBusinessUnits();
        },
        watch:{
            selectedUserCaseloadBusinessUnitValue() {
                log(["Selected UserCaseload business unit changed", this.selectedUserCaseloadBusinessUnitValue]);
                this.getUserCaseloadData(this.selectedUserCaseloadBusinessUnitValue !== "all" ? this.selectedUserCaseloadBusinessUnitValue : false);
            },
            selectedTotalAdvertsPublishedBusinessUnitValue() {
                log(["Selected Total Adverts Published business unit changed", this.selectedTotalAdvertsPublishedBusinessUnitValue]);
                this.getTotalAdvertsPublishedGraphData(this.selectedTotalAdvertsPublishedBusinessUnitValue !== "all" ? this.selectedTotalAdvertsPublishedBusinessUnitValue : false);
            }
        },

    }
</script>